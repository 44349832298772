import server from "./axios.config.js";

export const getfarmworkTypeList = function() {
  return server({
    method: "get",
    url: "http://110.87.103.59:18086/farmfriend/farmworkType/getList"
  });
};

export const postfarmworkTypeSeve = function(
  landIds,
  cropIds,
  typeId,
  workDate,
  cultivationCondition
) {
  return server({
    method: "post",
    url: "http://110.87.103.59:18086/farmfriend/farmwork/save",
    data: {
      land_ids: landIds,
      crop_ids: cropIds,
      type_id: typeId,
      work_date: workDate,
      cultivation_condition: cultivationCondition
    }
  });
};

export const getCorpByLandIds = function(landIds) {
  return server({
    method: "get",
    url: `http://110.87.103.59:18086/farmfriend/crop/getByLandIds?landIds=${landIds}`
  });
};

export const getMySelectList = function() {
  return server({
    method: "get",
    url: "http://110.87.103.59:18086/farmfriend/land/selectList"
  });
};

export const SelectConditionList = function(cropIds, landIds) {
  return server({
    method: "get",
    url: `http://110.87.103.59:18086/farmfriend/cropType/selectConditionList?cropIds=${cropIds}&landIds=${landIds}`
  });
};
