<template>
  <div>
    <van-nav-bar
      title="选择作物"
      left-text="返回"
      left-arrow
      @click-left="Bank"
    />
    <van-checkbox-group v-model="result">
      <van-cell-group>
        <van-cell
          v-for="(item, index) in list"
          clickable
          :key="index"
          @click="toggle(index, item.id)"
        >
          <div>{{ item.name }}</div>
          <template #right-icon>
            <van-checkbox :name="item" ref="checkboxes" />
          </template>
        </van-cell>
      </van-cell-group>
      <van-field
        readonly
        clickable
        name="da_crops"
        :value="da_crops"
        label="栽培条件"
        placeholder="请选择"
        @click="showCrops = true"
      />
      <van-popup v-model="showCrops" position="bottom">
        <van-picker
          show-toolbar
          :columns="cropsData"
          value-key="name"
          @confirm="onConfirm"
          @cancel="showCrops = false"
        />
      </van-popup>
    </van-checkbox-group>
    <div style="width:90%; margin: 10px auto">
      <van-button round block type="info" native-type="submit" style="background:#001f90" @click="seve()"
        >下一步</van-button
      >
    </div>
  </div>
</template>

<script>
import {
  NavBar,
  Cell,
  Field,
  Popup,
  Picker,
  CheckboxGroup,
  CellGroup,
  Checkbox,
  Button,
  Notify
} from "vant";
import { getCorpByLandIds, SelectConditionList } from "@/service/farmworkType";
export default {
  name: "PublicCrops",
  data() {
    return {
      da_crops: "",
      da_crops_id: "",
      list: ["a", "b"],
      result: [],
      cropIds: [],
      cropsData: [],
      showCrops: false
    };
  },
  components: {
    VanNavBar: NavBar,
    VanCell: Cell,
    VanCheckboxGroup: CheckboxGroup,
    VanCheckbox: Checkbox,
    VanCellGroup: CellGroup,
    VanButton: Button,
    "van-field": Field,
    "van-popup": Popup,
    "van-picker": Picker,
    [Notify.Component.name]: Notify.Component
  },
  mounted() {
    this.getlist();
  },
  methods: {
    Bank() {
      this.$router.go(-1);
    },
    onConfirm(crops) {
      this.da_crops = crops.name;
      this.da_crops_id = crops.id;
      this.showCrops = false;
    },
    toggle(index, cropIds) {
      this.$refs.checkboxes[index].toggle();
      if (this.$refs.checkboxes[index].checked === false) {
        this.cropIds.push(cropIds);
      } else if (this.$refs.checkboxes[index].checked === true) {
        this.cropIds.splice(index, 1);
      }
      this.getSelec(this.cropIds, this.$store.state.landIds);
    },
    getSelec(cropId, landId) {
      const cropIds = cropId.toString();
      const landIds = landId.toString();
      SelectConditionList(cropIds, landIds).then(res => {
        this.cropsData = res.data;
      });
    },
    getlist() {
      getCorpByLandIds(this.$store.state.landIds).then(res => {
        if (res.data.length > 0) {
          this.list = res.data;
        } else {
          Notify({
            type: "warning",
            message: "有相同的作物才能进行此农事记录"
          });
          this.Bank();
        }
      });
    },
    seve() {
      var cropIds = [];
      for (var i = 0; i < this.cropIds.length; i++) {
        if (cropIds.indexOf(this.cropIds[i]) === -1) {
          cropIds.push(this.cropIds[i]);
        }
      }
      this.$store.commit("setCropIds", cropIds);
      this.$store.commit("setda_crops_id", this.da_crops_id);
      if (cropIds.length > 0) {
        this.$router.push({
          path: "/farmworkType"
        });
      } else {
        Notify({ type: "warning", message: "请选择作物" });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
